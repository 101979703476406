var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap doc_req_pop", staticStyle: { width: "900px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "popup_cont screen_area" }, [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.ONEX070G090.041")) + " "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text_right" }),
            _vm.delegateYn === "Y"
              ? _c("div", { staticClass: "content_box" }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.002"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    _vm.nowDate,
                                    "."
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.003"))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.038"))),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.004"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.delegateYn === "Y"
              ? _c("div", { staticClass: "content_box mt10" }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(1),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.005"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(" " + _vm._s(_vm.bookingInfo.blNo) + " "),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.006"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.vslNm) + " "),
                            ]),
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.voyNo) + " "),
                            ]),
                            _c("span", { staticClass: "dv col_4" }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.007"))),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "tbl_form" },
                            [
                              _vm._l(
                                _vm.formData.cntrNoList,
                                function (item, idx) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: item.cntrNo,
                                        style:
                                          idx % 3 == 2
                                            ? "display:inline-block; width: 30%; float: none;"
                                            : "display:inline-block; width: 30%; float: left;",
                                      },
                                      [_vm._v(" " + _vm._s(item.cntrNo) + " ")]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.delegateYn === "Y"
                    ? _c("div", { staticClass: "normal_box" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("msg.ONEX070G090.008", {
                                consigneeEnm: _vm.bookingInfo.cneCstEnm,
                              })
                            )
                          ),
                        ]),
                        _c("p", { staticClass: "mt10" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.009"))),
                        ]),
                        _c("ol", { staticClass: "mt10" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX070G090.010")) + " "
                          ),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.011"))),
                          ]),
                          _c("li", { staticClass: "mt10" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.012"))),
                          ]),
                        ]),
                        _c("p", { staticClass: "text_center mt10" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$ekmtcCommon.changeDatePattern(
                                _vm.nowDate,
                                "."
                              )
                            )
                          ),
                        ]),
                        _c("p", { staticClass: "text_center mt10" }, [
                          _c("span", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.agreeYn,
                                  expression: "agreeYn",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "no",
                                id: "chk1",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.agreeYn)
                                  ? _vm._i(_vm.agreeYn, null) > -1
                                  : _vm._q(_vm.agreeYn, "Y"),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.agreeYn,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.agreeYn = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.agreeYn = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.agreeYn = $$c
                                  }
                                },
                              },
                            }),
                            _vm._m(2),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX070G090.039")) + " ("
                          ),
                          _c("span", { staticClass: "ml10 mr10" }, [
                            _vm._v(_vm._s(_vm.bookingInfo.actShprCstEnm)),
                          ]),
                          _vm._v(
                            ")" + _vm._s(_vm.$t("msg.ONEX070G090.040")) + " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c("div", { staticClass: "content_box" }, [
                  _c("p", { staticClass: "mt5" }, [
                    _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.surrenderBlPop()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.025")))]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.serviceCtrCd === "KR" &&
                    _vm.$store.getters["auth/getCalcEiCatCd"] === "I"
                      ? _c("span", { staticClass: "ml5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.moveDelegateReqPage()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.026")))]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.001"))),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(3),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.029"))),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "filebox",
                              attrs: { id: "file_att" },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "5px" } },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectedBlNo,
                                          expression: "selectedBlNo",
                                        },
                                      ],
                                      staticClass: "wid150 selectBlNo",
                                      staticStyle: {
                                        "background-color": "white",
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.selectedBlNo = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function ($event) {
                                            $event.preventDefault()
                                            ;[
                                              _vm.delegateCheck(),
                                              _vm.getFileInfo(),
                                            ]
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.ONEX070G090.042"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.blNoList, function (item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item.blNo,
                                            style:
                                              item.succFlag === "Y"
                                                ? "color: gray; font-weight: bold;"
                                                : "",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.blNo) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm.uploadParam.length === 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray file",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.uploadFilePop()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("msg.ONEX070G090.030")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.uploadParam.length > 0
                                ? _c("div", [
                                    _c("span", {
                                      staticClass: "tbl_icon attach",
                                    }),
                                    _c("a", { staticClass: "text_link" }, [
                                      _vm._v(
                                        _vm._s(_vm.uploadParam[0].originalName)
                                      ),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "tbl_icon attdel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeUploadFile(
                                              _vm.selectedBlNo
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("X")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "border_left_none" }),
                      ]),
                    ]),
                  ]),
                  _vm.serviceCtrCd === "KR"
                    ? _c("p", { staticClass: "txt_desc mt10" }, [
                        _c("span", { staticClass: "font_medium" }, [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.ONEX070G090.020")) + "]"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX070G090.019")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX070G090.021")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX070G090.022"))
                        ),
                        _c("br"),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "txt_desc mt10" }, [
                    _c("span", { staticClass: "font_medium" }),
                    _vm._v(" " + _vm._s(_vm.$t("msg.ONEX070G090.062")) + " "),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt10 text_right" }, [
              _vm.allSaveYn === "Y"
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg mr5",
                      on: {
                        click: function ($event) {
                          return _vm.reqAllSave()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.043")))]
                  )
                : _c("a", { staticClass: "button gray lg mr5" }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.043"))),
                  ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button blue lg mr5",
                  on: {
                    click: function ($event) {
                      return _vm.reqBlSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.044")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  on: {
                    click: function ($event) {
                      return _vm.confirmClose()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.034")))]
              ),
            ]),
          ]),
        ]
      ),
      _vm.uploadFlag === "N"
        ? _c(
            "win-layer-pop",
            {
              ref: "SurrenderRequestDelNPop",
              staticClass: "surrender_popup_dim",
            },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "single-file-yn": true,
                      "parent-info": _vm.popParams,
                    },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "surrender_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.uploadParam,
                      "single-file-yn": true,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk1" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }