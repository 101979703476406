var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", "padding-bottom": "20px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup_cont capture_area" },
            _vm._l(_vm.bookingInfoList, function (bookingInfo, i) {
              return _c(
                "div",
                { key: "sub_" + i, staticClass: "capture_sub_area" },
                [
                  _c("h1", { staticClass: "page_title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("msg.ONEX070G090.001")) + " "),
                  ]),
                  _c("div", { staticClass: "content_box" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(0, true),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.002"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$ekmtcCommon.changeDatePattern(
                                      _vm.nowDate,
                                      "."
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.003"))),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.038"))),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.004"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(" " + _vm._s(bookingInfo.shprCstNm) + " "),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "content_box mt10" }, [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(1, true),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.005"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(" " + _vm._s(bookingInfo.blNo) + " "),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.006"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(" " + _vm._s(bookingInfo.vslNm) + " "),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(" " + _vm._s(bookingInfo.voyNo) + " "),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.007"))),
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "tbl_form" },
                              [
                                _vm._l(
                                  bookingInfo.cntrNoList,
                                  function (item, idx) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: item.cntrNo,
                                          style:
                                            idx % 5 == 4
                                              ? "display:inline-block; width: 20%; float: none;"
                                              : "display:inline-block; width: 20%; float: left;",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.cntrNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "normal_box" }, [
                      _c("div", [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX070G090.052")) + " "
                          ),
                          _c("span", { staticClass: "color_red" }, [
                            _vm._v(
                              "「 " + _vm._s(bookingInfo.cneCstEnm) + " 」"
                            ),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX070G090.053")) + " "
                          ),
                        ]),
                        _c("br"),
                        _c("br"),
                        _c("p", { staticClass: "mt10" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX070G090.054")) + " "
                          ),
                        ]),
                        _c("br"),
                        _c("br"),
                        _c("ol", { staticClass: "mt10 ol_mb300" }, [
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX070G090.055")) + " "
                            ),
                          ]),
                          _c("li", { staticClass: "mt10" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX070G090.056")) + " "
                            ),
                          ]),
                          _c("li", { staticClass: "mt10" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX070G090.057")) + " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", [
                        _c("p", { staticClass: "mt20" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.058"))),
                        ]),
                        _c("p", { staticClass: "mt20 font_medium" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.059"))),
                        ]),
                        _c("p", { staticClass: "mt20" }, [
                          _vm._v(
                            " (" + _vm._s(_vm.$t("msg.ONEX070G090.060")) + ")"
                          ),
                          _c("br"),
                          _c("span", { staticClass: "color_orange" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX070G090.061"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "mt10 text_right" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg mr5 btn_print",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.screenPrint()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.051")))]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX070G090.034")))]
            ),
          ]),
        ]
      ),
      _c("div", { attrs: { id: "pdf_area" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }