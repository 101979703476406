<template>
  <div class="popup_wrap doc_req_pop" style="width:900px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont screen_area">
        <!-- popup_cont -->
        <h1 class="page_title">
          {{ $t('msg.ONEX070G090.041') }}
        </h1> <!-- Surrender Bl 발행 요청서 제출 -->
        <div class="text_right">
        </div>
        <div v-if="delegateYn === 'Y'" class="content_box">
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX070G090.002') }}</th><!-- DATE -->
                <td>
                  <div class="tbl_form">
                    {{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G090.003') }}</th><!-- TO -->
                <td>{{ $t('msg.ONEX070G090.038') }}</td><!-- KOREA MARINE TRANSPORT CO., LTD. -->
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G090.004') }}</th><!-- FROM -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.shprCstNm }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div v-if="delegateYn === 'Y'" class="content_box mt10">
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX070G090.005') }}</th><!-- Bill of Lading No. -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.blNo }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G090.006') }}</th><!-- Vessel/Voyage -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.vslNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.voyNo }}
                    </span>
                    <span class="dv col_4">
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G090.007') }}</th><!-- Container No. List -->
                <td>
                  <div class="tbl_form">
                    <template v-for="(item, idx) in formData.cntrNoList">
                      <div :key="item.cntrNo" :style="idx % 3 == 2 ? 'display:inline-block; width: 30%; float: none;' : 'display:inline-block; width: 30%; float: left;'">
                        {{ item.cntrNo }}
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="delegateYn === 'Y'" class="normal_box">
            <p>{{ $t('msg.ONEX070G090.008', { consigneeEnm: bookingInfo.cneCstEnm }) }}</p>
            <p class="mt10">{{ $t('msg.ONEX070G090.009') }}</p>
            <ol class="mt10">
              {{ $t('msg.ONEX070G090.010') }}
              <li>{{ $t('msg.ONEX070G090.011') }}</li>
              <li class="mt10">{{ $t('msg.ONEX070G090.012') }}</li>
            </ol>
            <p class="text_center mt10">{{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}</p>
            <p class="text_center mt10">
              <span>
                <input
                  v-model="agreeYn"
                  type="checkbox"
                  name="no"
                  id="chk1"
                  true-value="Y"
                  false-value="N"
                >
                <label for="chk1"><span class="offscreen">No</span></label>
              </span>
              {{ $t('msg.ONEX070G090.039') }} (<span class="ml10 mr10">{{ bookingInfo.actShprCstEnm }}</span>){{ $t('msg.ONEX070G090.040') }}
            </p>
          </div>
        </div><!-- content_box // -->

        <div v-if="delegateYn === 'N'" class="content_box">
          <!-- content_box -->
          <!-- 문구 국내/해외 모두 숨김 -->
          <!-- <p>
            {{ $t('msg.ONEX070G090.024') }}
          </p> -->
          <p class="mt5">
            <span><a class="button sm" href="#" @click.prevent="surrenderBlPop()">{{ $t('msg.ONEX070G090.025') }}</a></span> <!-- Surrender B/L 발행 요청서 양식 다운 -->
            <span v-if="serviceCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'I'" class="ml5"><a class="button sm" href="#" @click.prevent="moveDelegateReqPage()">{{ $t('msg.ONEX070G090.026') }}</a></span> <!-- 위수임 신청 바로가기 -->
          </p>

          <h2 class="content_title">{{ $t('msg.ONEX070G090.001') }}</h2> <!-- Surrender Request -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX070G090.029') }}</th><!-- 제출 -->
                <td>
                  <div class="filebox" id="file_att">
                    <span style="margin-right:5px;">
                      <select class="wid150 selectBlNo" style="background-color: white;" v-model="selectedBlNo" @change.prevent="[delegateCheck(), getFileInfo()]">
                        <option value="">{{ $t('msg.ONEX070G090.042') }}</option> <!-- 선택 -->
                        <option
                          v-for="item in blNoList"
                          :key="item.blNo"
                          :style="item.succFlag === 'Y' ? 'color: gray; font-weight: bold;' : ''"
                        >
                          {{ item.blNo }}
                        </option>
                      </select>
                    </span>
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX070G090.030') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a class="tbl_icon attdel" @click="removeUploadFile(selectedBlNo)">X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                  <!--<a class="button sm" href="#">제출</a>-->
                </td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc mt10" v-if="serviceCtrCd === 'KR'">
            <span class="font_medium">[{{ $t('msg.ONEX070G090.020') }}]</span>
            {{ $t('msg.ONEX070G090.019') }} <span class="txt_bar"></span> {{ $t('msg.ONEX070G090.021') }} : {{ $t('msg.ONEX070G090.022') }}<br>
          </p>
          <p class="txt_desc mt10">
            <span class="font_medium"></span>
            {{ $t('msg.ONEX070G090.062') }}
          </p><!-- 모든 파일이 등록 후 저장되어야 최종 제출이 가능합니다 -->
        </div><!-- content_box // -->

        <div class="mt10 text_right">
          <!--<a class="button blue lg mr5" v-if="allSaveYn === 'Y'" @click="reqAllSave()">{{ $t('msg.ONEX070G090.043') }}</a>--> <!-- 최종 제출 -->
          <a v-if="allSaveYn === 'Y'" class="button blue lg mr5" @click="reqAllSave()">{{ $t('msg.ONEX070G090.043') }}</a> <!-- 최종 제출 -->
          <a v-else class="button gray lg mr5">{{ $t('msg.ONEX070G090.043') }}</a> <!-- 최종 제출 -->
          <a class="button blue lg mr5" @click="reqBlSave()">{{ $t('msg.ONEX070G090.044') }}</a> <!-- 저장 -->
          <a class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX070G090.034') }}</a> <!-- 닫기 -->
        </div>
      </div><!-- popup_cont -->
    </form>
    <win-layer-pop v-if="uploadFlag === 'N'" ref="SurrenderRequestDelNPop" class="surrender_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :single-file-yn="true"
        :parent-info="popParams"
        @close="closePopup"
      />
    </win-layer-pop>
    <win-layer-pop v-if="uploadFlag === 'Y'" ref="uploadPop" class="surrender_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="uploadParam"
        :single-file-yn="true"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import SurrenderRequestDelNPop from '@/pages/trans/popup/SurrenderRequestDelNPop'
import SurrenderRequestDelNIdPop from '@/pages/trans/popup/SurrenderRequestDelNIdPop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'SurrenderRequestPop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    SurrenderRequestDelNPop,
    SurrenderRequestDelNIdPop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNoArr: []
        }
      }
    }
  },
  data () {
    return {
      lang: '',
      //selectBox에 있는 Bl No.
      blNoList: [],
      //선택한 Bl No.
      selectedBlNo: '',
      //오늘 날짜
      nowDate: '',
      //Bl No.의 전체 플래그
      allSaveYn: 'N',
      //현재 blNo의 위수임 플래그
      delegateYn: 'N',
      //제출 동의 플래그
      agreeYn: 'N',
      //파일이 저장된 blNo
      succBlNo: [],
      //blNo에 저장되어있는 정보
      bookingInfo: {
        type: Object,
        default: null
      },
      //첨부파일 정보
      selectFunc: null,
      customComponent: null,
      uploadParam: [],
      //접속국가 언어
      serviceCtrCd: '',
      //업로드 팝업과 기본 팝업을 구분하기 위한 플래그
      uploadFlag: 'N',
      //Surrender Bl 요청서에 넘겨줘야할 정보
      popParams: {},
      //실제로 제출해야하는 정보
      formData: {
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        //Bl No.에 묶여진 컨테이너 리스트
        cntrNoList: [],
        uploadFileInfos: []
      }
    }
  },
  watch: {
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.lang = this.auth.serviceLang
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNoArr)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      // 접속국가에 따라 연락처 보여주기
      if (!this.auth.userId) {
        this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')
      } else {
        this.serviceCtrCd = this.memberDetail.userCtrCd
      }

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }

      await Promise.all([
        //nowDate 가져오기
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        })
      ])
      //BL No. selectBox 세팅하기
      // succFlag 추가
      if (parentInfo.blNoArr.length > 0) {
        for (let i = 0; i < parentInfo.blNoArr.length; i++) {
          let tmpBlInfo = {
            blNo: parentInfo.blNoArr[i],
            succFlag: 'N'
          }

          // blNoList에 넣기
          TH.blNoList.push(tmpBlInfo)
        }
      }

      //BL_NO가 1개일 경우 selectBox 초기화셋팅
      if (TH.blNoList.length === 1) {
        TH.selectedBlNo = TH.blNoList[0].blNo

        this.delegateCheck()
        this.getFileInfo()
      }
    },
    // 위수임이 Y인경우 booking정보 화면에 뿌려주기
    async setBookingInfo (result) {
      const TH = this

      //bookingInfo와 컨테이너리스트 초기화
      this.bookingInfo = {}
      this.formData.cntrNoList = []

      this.bookingInfo = result

      if (this.$ekmtcCommon.isNotEmpty(this.bookingInfo.blNo)) {
        const blNo = this.bookingInfo.blNo

        await docs.getBkgContainerInfo(blNo).then(res => {
          const cntrList = res.data

          console.log('###### getBkgContainerInfo ######')
          console.log(cntrList)

          if (cntrList !== undefined && cntrList.length > 0) {
            TH.formData.cntrNoList = cntrList
          }
        })
      }
    },
    // 저장시 각 succBl에 담아주기
    async reqBlSave () {
      let isOk = true
      const TH = this

      if (isOk) {
        if (this.delegateYn === 'Y' && TH.agreeYn !== 'Y') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.023')) //제출 동의체크를 해주시기 바랍니다.
          return
        }

        if (this.delegateYn === 'N' && this.selectedBlNo === '') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047')) // Bl No.를 선택해 주십시오.
          return
        }

        if (this.delegateYn === 'N' && TH.uploadParam.length === 0) {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.045')) // 제출할 파일이 없습니다.
          return
        }

        if (this.delegateYn === 'Y' && TH.uploadParam.length > 0) {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.046')) // 이미 파일을 제출하셨습니다.
          return
        }

        if (this.delegateYn === 'Y') {
        const frm = document.querySelector('#frm_req_pop')
        const ele = frm.querySelector('.screen_area')
        this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

        await this.$ekmtcCommon.screenToPdf({ cssSelector: '.screen_area' })
          .then(async (res) => {
            const reqData = new FormData()
            const fileName = this.$t('msg.ONEX070G090.001') + '_' + TH.selectedBlNo + '.pdf'
            reqData.append('files', res.output('blob'), fileName)

            await commons.tempFileUpload(reqData).then((result) => {
                console.log('####FileInfo#####')
                const fileId = result.data.fileId

                if (result.data.fileId !== undefined) {
                  const fileObj = {
                    blNo: TH.selectedBlNo,
                    fileId: fileId,
                    originalName: fileName
                  }

                  TH.uploadParam.push(fileObj)

                  //succbl배열에 넣어주기
                  TH.succBlNo.push(
                    {
                      blNo: TH.selectedBlNo,
                      agreeYn: 'Y',
                      disabled: 'Y',
                      fileInfo: TH.uploadParam
                    }
                  )
                  //succFlag바꿔주기
                  TH.blNoList.forEach(item => {
                    if (item.blNo === TH.selectedBlNo) {
                      item.succFlag = 'Y'
                    }
                  })

                  this.$ekmtcCommon.alertDefault(TH.selectedBlNo + this.$t('msg.ONEX070G090.048')) // 저장되었습니다.
                } else {
                  this.$ekmtcCommon.alertDefault(TH.selectedBlNo + this.$t('msg.ONEX070G090.049')) // 저장 실패하였습니다.
                }
              })
              .catch(err => {
                console.log(err)
              })
              TH.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
          })
        } else {
          //파일이 이미 저장된 경우가 아닌 경우
          const tmpFlag = TH.succBlNo.find(ele => ele.blNo === TH.selectedBlNo)

          if (tmpFlag === undefined) {
            //succbl배열에 넣어주기
            TH.succBlNo.push(
              {
                blNo: TH.selectedBlNo,
                agreeYn: 'Y',
                disabled: 'Y',
                fileInfo: TH.uploadParam
              }
            )
            //succFlag바꿔주기
            TH.blNoList.forEach(item => {
              if (item.blNo === TH.selectedBlNo) {
                item.succFlag = 'Y'
              }
            })

            this.$ekmtcCommon.alertDefault(TH.selectedBlNo + this.$t('msg.ONEX070G090.048')) // 저장되었습니다.
          } else {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.046')) // 이미 파일을 제출하셨습니다.
            return
          }
        }
      }
      //전체 여부 체크
      this.setAllSaveYn()
    },
    //최종 제출 버튼
    reqAllSave () {
      const TH = this

      if (TH.succBlNo.length < TH.blNoList.length) {
        TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.050'))
        return
      }

      TH.formData.uploadFileInfos = []

      TH.succBlNo.forEach(item => {
        item.fileInfo[0].blNo = item.blNo
        TH.formData.uploadFileInfos.push(item.fileInfo[0])
      })

      //파일 정보 넘기기
      TH.$emit('selectFunc', TH.formData.uploadFileInfos)
      TH.$emit('close')
    },
    //파일 여부 가져오기
    getFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedBlNo

      TH.uploadParam = []

      if (TH.succBlNo.length > 0) {
        TH.succBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.uploadParam = item.fileInfo
          }
        })
      }
    },
    // allSaveYn 체크
    setAllSaveYn () {
      console.log('@@@@@@@@@@@@@@@allSaveYnCheck@@@@@@@@@@@@@@@@')
      if (this.succBlNo.length >= this.blNoList.length) {
        this.allSaveYn = 'Y'
      } else {
        this.allSaveYn = 'N'
      }
    },
    // Bl No 리스트의 Bl No를 선택했을시 위수임 체크
    async delegateCheck () {
      const TH = this
      if (TH.selectedBlNo !== '' && TH.selectedBlNo !== undefined) {
        await this.setDelegateYn(TH.selectedBlNo)
      } else {
        TH.delegateYn = 'N'
      }
    },
    // booking정보 가져오기
    async getBookingInfo () {
      let arrBlNo = []

      arrBlNo.push(this.selectedBlNo)
      await docs.getBlSurrCopyInfo(arrBlNo).then(async res => {
        // blNo 하나만 나오게 구성
        const result = res.data.blInfoList[0]
        this.bookingInfo = result
        this.formData.cntrNoList = result.cntrNoList
        //await this.setBookingInfo(result)
      })
    },
    // Surrender Bl 요청서 다운
    async surrenderBlPop () {
      const TH = this

      //팝업 플래그 바꾸기
      TH.uploadFlag = 'N'

      let blNoArr = []
      let delegateCnt = 0

      for (const b of TH.blNoList) {
        await transCommon.getBlDelegateYn({ blNo: b.blNo, arrCstGrpCatCd: ['30', '35'] }).then(async (res) => {
          if (res.data !== 'Y') {
            blNoArr.push(b.blNo)
          } else {
            delegateCnt++
          }
        })
      }

      if (this.lang === 'KOR') {
        if (blNoArr.length === 0) {
          this.$ekmtcCommon.alertDefault('다운로드 가능한 B/L이 없습니다. B/L을 개별선택하여 발행 요청을 해주시기 바랍니다.')
          return
        } else if (delegateCnt > 0) {
          this.$ekmtcCommon.alertDefault('위수임이 신청된 B/L을 제외하고 다운로드 됩니다.')
        }
      } else {
        if (blNoArr.length === 0) {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK185'))
          return
        }
      }

      TH.popParams = {
        blNoInfo: blNoArr,
        delegateYn: TH.delegateYn
      }

      let popNm = SurrenderRequestDelNPop

      if (this.memberDetail.userCtrCd === 'ID') {
        popNm = SurrenderRequestDelNIdPop
      }

      this.openPopup(popNm, 'N')
    },
    // 위수임 신청 바로가기
    async moveDelegateReqPage () {
      const TH = this
      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    openPopup (compNm, alertFlag) {
      if (this.$ekmtcCommon.isEmpty(alertFlag)) {
        alertFlag = 'Y'
      }

      if (this.selectedBlNo === '' && alertFlag === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .surrender_popup_dim')
    },
    closePopup (compNm) {
      this.customComponent = null
      this.selectFunc = null

      if (compNm !== 'confirmClose') {
        this.$ekmtcCommon.layerClose('.doc_req_pop .surrender_popup_dim')
      } else {
        this.$emit('close')
      }
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile (blNo) {
      this.uploadParam.splice(0, 1)

      const removeIndex = this.succBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.succBlNo.splice(removeIndex, 1)
        this.setAllSaveYn()
      }
      this.customComponent = null
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam
      this.uploadFlag = 'Y'

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    //위수임 여부 판단
    async setDelegateYn (blNo) {
      // CD_ID = 01007 (30: 수출 ALL, 35: Surrender B/L 요청)
      await transCommon.getBlDelegateYn({ blNo: blNo, arrCstGrpCatCd: ['30', '35'] }).then(async (res) => {
        // if (blNo === 'PUSE021101') {
        if (res.data === 'Y') {
            this.delegateYn = 'Y'
            this.getBookingInfo()
          } else {
            this.delegateYn = 'N'
          }
      })
    },
    async confirmClose () {
      const msg = this.$t('msg.ONEX070G090.033') // 작성한 내용은 저장되지 않습니다.

      this.$ekmtcCommon.confirmCallBack(msg, () => { this.closePopup('confirmClose') })
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
