var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb10" },
    [
      _vm.memberDetail.userCtrCd === "KR"
        ? [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.002"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_col" }, [
              _c(
                "colgroup",
                [
                  _vm.memberDetail.userCtrCd === "KR"
                    ? [
                        _c("col", { attrs: { width: "4%" } }),
                        _c("col", { attrs: { width: "12%" } }),
                        _c("col", { attrs: { width: "11%" } }),
                        _c("col", { attrs: { width: "13%" } }),
                        _c("col", { attrs: { width: "13%" } }),
                        _c("col", { attrs: { width: "16%" } }),
                        _c("col", { attrs: { width: "12%" } }),
                        _c("col", { attrs: { width: "11%" } }),
                        _c("col", { attrs: { width: "8%" } }),
                      ]
                    : [
                        _c("col", { attrs: { width: "7%" } }),
                        _c("col", { attrs: { width: "15%" } }),
                        _c("col", { attrs: { width: "14%" } }),
                        _c("col", { attrs: { width: "16%" } }),
                        _c("col", { attrs: { width: "19%" } }),
                        _c("col", { attrs: { width: "15%" } }),
                        _c("col", { attrs: { width: "14%" } }),
                      ],
                ],
                2
              ),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.003")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.004")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.005")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.006")))]),
                  _vm._v(" "),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.007")))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.008")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.009")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.010")))]),
                  _vm._v(" "),
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.009")))])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(_vm.formData.items, function (item, index) {
                    return [
                      _c("tr", { key: "main_" + index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(item.blNo)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.cfmStsNm))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.setStsNm))]),
                        _vm._v(" "),
                        _vm.memberDetail.userCtrCd === "KR"
                          ? _c("td", [_vm._v(_vm._s(item.issStsNm))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", {
                          staticClass: "text_right",
                          domProps: { innerHTML: _vm._s(item.amt) },
                        }),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.cntrStsNm))]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", { staticClass: "label red md" }, [
                            _vm._v(_vm._s(item.blStatusNm)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.memberDetail.userCtrCd === "KR"
                          ? _c("td", [
                              _c(
                                "button",
                                {
                                  class:
                                    item.errorFlag == "Y"
                                      ? "tbl_icon up"
                                      : "tbl_icon down",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.errorShowing(item)
                                    },
                                  },
                                },
                                [_vm._v("down")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      item.errorFlag == "Y"
                        ? _c("tr", { key: "sub_" + index }, [
                            _c(
                              "td",
                              { staticClass: "pd0", attrs: { colspan: "9" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "inner_table blstatus" },
                                  [
                                    _c("table", { staticClass: "tbl_row t2" }, [
                                      _vm._m(0, true),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "button sh btn_error_area",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.goBLModify(
                                                      item.blNo,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "msg.ONEX060P012.001"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "button sh btn_error_area",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.goFreetime(
                                                      item.blNo
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "msg.ONGO080G010.034"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "valign_top text_left",
                                            },
                                            _vm._l(
                                              item.errorList,
                                              function (errorItem, errorIndex) {
                                                return _c(
                                                  "ul",
                                                  {
                                                    key:
                                                      index + "_" + errorIndex,
                                                    staticClass:
                                                      "bul_list lh_short",
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "color_black",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              errorItem.blErrMsg
                                                            ) +
                                                            " "
                                                        ),
                                                        errorItem.blErrCd ==
                                                          "212" ||
                                                        errorItem.blErrCd ==
                                                          "235"
                                                          ? [
                                                              _c("br"),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "color_red",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "(" +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "msg.ONEX070G040.011"
                                                                        )
                                                                      ) +
                                                                      " : " +
                                                                      _vm._s(
                                                                        item.obrdDt
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
      _c("h2", { staticClass: "content_title" }, [
        _c("span", [_vm._v(_vm._s(_vm.blStrings[0]))]),
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v(_vm._s(_vm.blStrings[1])),
        ]),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tbl_row" }, [
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              { staticClass: "border_left_none" },
              [
                _vm._l(_vm.reqBizCatCdList, function (item, index) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.reqBizCatCd,
                          expression: "formData.reqBizCatCd",
                        },
                      ],
                      key: index,
                      attrs: {
                        disabled:
                          _vm.issStstus === "SUR_TEL" && item.cd !== "02",
                        type: "radio",
                        id: "reqBizCatCd_" + index,
                        name: "reqBizCatCd",
                      },
                      domProps: {
                        value: item.cd,
                        checked: _vm._q(_vm.formData.reqBizCatCd, item.cd),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(
                              _vm.formData,
                              "reqBizCatCd",
                              item.cd
                            )
                          },
                          function ($event) {
                            return _vm.setReqBizCatCd(item.cd)
                          },
                        ],
                      },
                    }),
                    _c(
                      "label",
                      {
                        key: "label_" + index,
                        staticClass: "bl-highlight mr20",
                        attrs: { for: "reqBizCatCd_" + index },
                      },
                      [_c("span"), _vm._v(_vm._s(item.cdNm))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
          _c(
            "tr",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.memberDetail.userCtrCd === "IN",
                  expression: "memberDetail.userCtrCd === 'IN'",
                },
              ],
            },
            [
              _c(
                "td",
                { staticClass: "border_left_none" },
                [
                  _vm._l(_vm.docKndList, function (item, index) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.docKndCd,
                            expression: "formData.docKndCd",
                          },
                        ],
                        key: index,
                        attrs: {
                          type: "radio",
                          id: "docKndCd_" + index,
                          name: "docKndCd",
                        },
                        domProps: {
                          value: item.cd,
                          checked: _vm._q(_vm.formData.docKndCd, item.cd),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "docKndCd", item.cd)
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          key: "label_" + index,
                          staticClass: "bl-highlight mr20",
                          attrs: { for: "docKndCd_" + index },
                        },
                        [_c("span"), _vm._v(_vm._s(item.cdNm))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
      _vm.formData.reqBizCatCd !== ""
        ? [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.013"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(1),
              _c("tbody", [
                ["IN", "HK"].includes(_vm.memberDetail.userCtrCd)
                  ? _c("tr", [
                      _c("th", [_vm._v("Upload Doc")]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedEtcBlNo,
                                        expression: "selectedEtcBlNo",
                                      },
                                    ],
                                    staticClass: "wid150 selectBlNo",
                                    staticStyle: {
                                      "background-color": "white",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.selectedEtcBlNo = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          $event.preventDefault()
                                          return _vm.getEtcFileInfo()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX070G090.042"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.etcBlNoList, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.blNo,
                                          style:
                                            item.succFlag === "Y"
                                              ? "color: gray; font-weight: bold;"
                                              : "",
                                        },
                                        [_vm._v(" " + _vm._s(item.blNo) + " ")]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm.etcUploadParam.length === 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button sm gray file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadEtcPopup()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.205")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.etcUploadParam.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.etcUploadParam, function (etc) {
                                    return _c("div", { key: etc.fileId }, [
                                      _c("span", {
                                        staticClass: "tbl_icon attach",
                                      }),
                                      _c("a", { staticClass: "text_link" }, [
                                        _vm._v(_vm._s(etc.originalName)),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "tbl_icon attdel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeEtcUploadFile(
                                                _vm.selectedEtcBlNo
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("X")]
                                      ),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.memberDetail.userCtrCd !== "KR"
                  ? _c("tr", [
                      _c("th", [_vm._v("Bank Slip")]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedJpBlNo,
                                        expression: "selectedJpBlNo",
                                      },
                                    ],
                                    staticClass: "wid150 selectBlNo",
                                    staticStyle: {
                                      "background-color": "white",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.selectedJpBlNo = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          $event.preventDefault()
                                          return _vm.getJpFileInfo()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX070G090.042"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.jpBlNoList, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.blNo,
                                          style:
                                            item.succFlag === "Y"
                                              ? "color: gray; font-weight: bold;"
                                              : "",
                                        },
                                        [_vm._v(" " + _vm._s(item.blNo) + " ")]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm.jpUploadParam.length === 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button sm gray file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadJpPopup()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.205")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.jpUploadParam.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.jpUploadParam, function (jp) {
                                    return _c("div", { key: jp.fileId }, [
                                      _c("span", {
                                        staticClass: "tbl_icon attach",
                                      }),
                                      _c("a", { staticClass: "text_link" }, [
                                        _vm._v(_vm._s(jp.originalName)),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "tbl_icon attdel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeJpUploadFile(
                                                _vm.selectedJpBlNo
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("X")]
                                      ),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.memberDetail.userCtrCd !== "KR" &&
                _vm.memberDetail.userCtrCd !== "JP"
                  ? _c("tr", [
                      _c("th", [_vm._v("Withholding Tax")]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedTaxBlNo,
                                        expression: "selectedTaxBlNo",
                                      },
                                    ],
                                    staticClass: "wid150 selectBlNo",
                                    staticStyle: {
                                      "background-color": "white",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.selectedTaxBlNo = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          $event.preventDefault()
                                          return _vm.getTaxFileInfo()
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("msg.ONEX070G090.042"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.taxBlNoList, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.blNo,
                                          style:
                                            item.succFlag === "Y"
                                              ? "color: gray; font-weight: bold;"
                                              : "",
                                        },
                                        [_vm._v(" " + _vm._s(item.blNo) + " ")]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm.taxUploadParam.length === 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button sm gray file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadTaxPopup()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBK100.205")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.taxUploadParam.length > 0
                              ? _c("div", [
                                  _c("span", {
                                    staticClass: "tbl_icon attach",
                                  }),
                                  _c("a", { staticClass: "text_link" }, [
                                    _vm._v(
                                      _vm._s(_vm.taxUploadParam[0].originalName)
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tbl_icon attdel",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeTaxUploadFile(
                                            _vm.selectedTaxBlNo
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("X")]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.formData.reqBizCatCd == "02"
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.014")))]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          _vm._l(_vm.uploadParam, function (item, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticStyle: { width: "500px" },
                                attrs: { "v-if": _vm.uploadParam.length > 0 },
                              },
                              [
                                _c("span", { staticClass: "tbl_icon attach" }),
                                _c("a", { staticClass: "text_link" }, [
                                  _vm._v(_vm._s(item.originalName)),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "tbl_icon attdel",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeUploadFile()
                                      },
                                    },
                                  },
                                  [_vm._v("X")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button sm",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.searchSurrReq()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.015")))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "position_relative" }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help ml5",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnOpenPop("O")
                                },
                              },
                            },
                            [_vm._v("도움말")]
                          ),
                          _vm.popFlag
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tooltip_wrap short position_absolute",
                                  staticStyle: {
                                    width: "300px",
                                    right: "0",
                                    top: "24px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "cont" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "close",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.fnOpenPop("C")
                                          },
                                        },
                                      },
                                      [_vm._v("close")]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "bul_list_sm t2" },
                                      [
                                        _c(
                                          "li",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("msg.ONEX070G040.016")
                                                ) +
                                                " "
                                            ),
                                            _vm.memberDetail.userCtrCd === "KR"
                                              ? [
                                                  _c("br"),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "title mt10",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.ONEX070G040.017"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "msg.ONEX070G040.018"
                                                        )
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "msg.ONEX070G040.019"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.020")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.reqUsrNm,
                          expression: "formData.reqUsrNm",
                        },
                      ],
                      staticClass: "col-6",
                      attrs: { type: "text", id: "reqUsrNm", name: "reqUsrNm" },
                      domProps: { value: _vm.formData.reqUsrNm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formData,
                            "reqUsrNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.021")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.mbpOfcNo,
                          expression: "formData.mbpOfcNo",
                        },
                      ],
                      staticClass: "col-6",
                      attrs: {
                        type: "text",
                        id: "mbpOfcNo",
                        name: "mbpOfcNo",
                        maxlength: "12",
                      },
                      domProps: { value: _vm.formData.mbpOfcNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formData,
                            "mbpOfcNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.formData.reqBizCatCd === "06"
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.022")))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formData.issPlcCd,
                                  expression: "formData.issPlcCd",
                                },
                              ],
                              staticClass: "col-6",
                              attrs: { name: "issPlcCd", id: "issPlcCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.formData,
                                      "issPlcCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    $event.preventDefault()
                                    return _vm.setEtcFlag.apply(null, arguments)
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.069"))),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.formData.issPlcCdArr,
                                function (plcCd, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      attrs: { id: plcCd.cd },
                                      domProps: { value: plcCd.cd },
                                    },
                                    [_vm._v(_vm._s(plcCd.cdNm))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          [
                            _vm.etcFlag === "Y"
                              ? _c("e-auto-complete-place", {
                                  attrs: {
                                    placeholder: "항구, 국가 입력(영어)",
                                    "ctr-cd": "KOR",
                                  },
                                  on: { change: _vm.changePort },
                                  model: {
                                    value: _vm.formData.ctrCd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "ctrCd", $$v)
                                    },
                                    expression: "formData.ctrCd",
                                  },
                                })
                              : _vm._e(),
                          ],
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.023")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(" " + _vm._s(_vm.blNoStrings) + " ")]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.024")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.reqCont,
                          expression: "formData.reqCont",
                        },
                      ],
                      attrs: {
                        id: "reqCont",
                        name: "reqCont",
                        placeholder: _vm.$t("msg.ONEX070G040.071"),
                      },
                      domProps: { value: _vm.formData.reqCont },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formData, "reqCont", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm.formData.reqBizCatCd === "06"
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.026")))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _vm._l(_vm.formData.items, function (item, index) {
                            return [
                              _c("input", {
                                key: index,
                                attrs: {
                                  type: "checkbox",
                                  id: "porDly_" + index,
                                  name: "pordly",
                                },
                                domProps: { value: _vm.formData.items.blNo },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePolDlyArr(item.blNo)
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  key: "label_" + index,
                                  staticClass: "mr20",
                                  attrs: { for: "porDly_" + index },
                                },
                                [_c("span"), _vm._v(_vm._s(item.blNo))]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.uploadFlag === "Y" && _vm.uploadJp === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "upload_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.jpUploadParam,
                      "single-file-yn": _vm.singleFileYn,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "Y" && _vm.uploadTax === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "upload_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.taxUploadParam,
                      "single-file-yn": true,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "Y" && _vm.uploadEtc === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "upload_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.etcUploadParam,
                      "single-file-yn": _vm.singleFileYn,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "N"
        ? _c(
            "win-layer-pop",
            { staticClass: "outer_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popParams },
                    on: {
                      close: _vm.closePopup,
                      selectFunc: _vm.selectFunc,
                      callback: _vm.selectFunc,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "84%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "170" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }