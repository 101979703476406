<template>
  <div class="popup_wrap doc_req_pop" style="width: 900px; padding-bottom: 20px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form id="frm_req_pop" @submit.prevent>
      <div class="popup_cont capture_area">
        <div
          v-for="(bookingInfo, i) in bookingInfoList"
          :key="'sub_' + i"
          class="capture_sub_area"
        >
          <!-- popup_cont -->
          <h1 class="page_title">
            {{ $t("msg.ONEX070G090.001") }}
          </h1>
          <div class="content_box">
            <!-- content_box -->
            <table class="tbl_row">
              <colgroup>
                <col width="200" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.002") }}</th>
                  <!-- DATE -->
                  <td>
                    <div class="tbl_form">
                      {{ $ekmtcCommon.changeDatePattern(nowDate, ".") }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.003") }}</th>
                  <!-- TO -->
                  <td>{{ $t("msg.ONEX070G090.038") }}</td>
                  <!-- KOREA MARINE TRANSPORT CO., LTD. -->
                </tr>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.004") }}</th>
                  <!-- FROM -->
                  <td>
                    <div class="tbl_form">
                      {{ bookingInfo.shprCstNm }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- content_box // -->

          <div class="content_box mt10">
            <!-- content_box -->
            <table class="tbl_row">
              <colgroup>
                <col width="200" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.005") }}</th>
                  <!-- Bill of Lading No. -->
                  <td>
                    <div class="tbl_form">
                      {{ bookingInfo.blNo }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.006") }}</th>
                  <!-- Vessel/Voyage -->
                  <td>
                    <div class="tbl_form">
                      <span class="dv col_3">
                        {{ bookingInfo.vslNm }}
                      </span>
                      <span class="dv col_3">
                        {{ bookingInfo.voyNo }}
                      </span>
                      <span class="dv col_4"> </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("msg.ONEX070G090.007") }}</th>
                  <!-- Container No. List -->
                  <td>
                    <div class="tbl_form">
                      <!-- <span class="dv col_3">XINU1441125</span>
                      <span class="dv col_3">XINU1429412</span>
                      <span class="dv col_3">XINU1342797</span> -->
                      <template v-for="(item, idx) in bookingInfo.cntrNoList">
                        <div
                          :key="item.cntrNo"
                          :style="
                            idx % 5 == 4
                              ? 'display:inline-block; width: 20%; float: none;'
                              : 'display:inline-block; width: 20%; float: left;'
                          "
                        >
                          {{ item.cntrNo }}
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="normal_box">
              <div>
                <p>
                  {{ $t("msg.ONEX070G090.052") }}
                  <span class="color_red">「 {{ bookingInfo.cneCstEnm }} 」</span>
                  {{ $t("msg.ONEX070G090.053") }}
                </p>
                <br /><br />
                <p class="mt10">
                  {{ $t("msg.ONEX070G090.054") }}
                </p>
                <br /><br />
                <ol class="mt10 ol_mb300">
                  <li>
                    {{ $t("msg.ONEX070G090.055") }}
                  </li>
                  <li class="mt10">
                    {{ $t("msg.ONEX070G090.056") }}
                  </li>
                  <li class="mt10">
                    {{ $t("msg.ONEX070G090.057") }}
                  </li>
                </ol>
              </div>
              <div>
                <p class="mt20">{{ $t("msg.ONEX070G090.058") }}</p>
                <!-- Yours faithfully, -->

                <p class="mt20 font_medium">{{ $t("msg.ONEX070G090.059") }}</p>
                <!-- For and Behalf of -->
                <p class="mt20">
                  ({{ $t("msg.ONEX070G090.060") }})<br />
                  <!-- 실화주 직인, 명판 날인요 -->
                  <span class="color_orange">{{
                    $t("msg.ONEX070G090.061")
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- content_box // -->
        </div>
      </div>
      <!-- popup_cont -->
      <div class="mt10 text_right">
        <a
          class="button blue lg mr5 btn_print"
          @click.prevent="screenPrint()"
        >{{ $t("msg.ONEX070G090.051") }}</a>
        <!-- 인쇄 -->
        <a class="button gray lg" @click="$emit('close')">{{
          $t("msg.ONEX070G090.034")
        }}</a>
        <!-- 닫기 -->
      </div>
    </form>
    <div id="pdf_area"></div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'SurrenderRequestDelNPop',
  components: {
    rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNoInfo: [],
          delegateYn: ''
        }
      }
    }
  },
  data () {
    return {
      bookingInfoList: [],
      formData: {
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        //Bl No.에 묶여진 컨테이너 리스트
        cntrNoList: [],
        uploadFileInfos: []
      },
      nowDate: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNoInfo)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(
        this.$t('msg.ONEX070G050.014'),
        function () {
          TH.$emit('close')
        }
      )
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      await Promise.all([
        commons.getServerTime().then((res) => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        docs.getBlSurrCopyInfo(parentInfo.blNoInfo).then((res) => {
          const result = res.data
          TH.bookingInfoList = result.blInfoList
          console.log('@@@@ TH.bookingInfoList: ', TH.bookingInfoList)
          console.log('TH.bookingInfoList.cntrNoList: ', TH.bookingInfoList[0].cntrNoList)
          // for (let i = 0; i < 500; i++) {
          //   TH.bookingInfoList[0].cntrNoList.push(TH.bookingInfoList[0].cntrNoList[0])
          // }
        })
      ])
    },
    screenPrint () {
      const ele = document.querySelector('.capture_area')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      this.$ekmtcCommon
        .screenToPrintPageSplit({ cssPageSelector: '.capture_sub_area' })
        .then((res) => {
          if (res === undefined || res === null) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
          }
          this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
        })
        .catch(() => {
          this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.doc_req_pop {
  width: 910px;
  height: 600px;
  overflow-y: scroll;
}
.doc_req_pop > .layer_close {
  z-index: 10;
}
.div_cntr_area + .div_cntr_area {
  margin-top: 5px;
}
.text_link {
  color: #000;
  padding-left: 2px;
}
.text_link:hover {
  text-decoration: underline !important;
}
.attdel {
  cursor: pointer;
}
.capture_sub_area {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* height: 1230px; */
}
.capture_sub_area {
  & > h1 {
    height: 50px;
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  & > div {
    box-sizing: border-box;
    &:nth-of-type(2) {
      /* height: calc(100% - 50px - 128px + 10px); */
      padding: 20px;
    }
  }
}
.normal_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ol_mb300 {
  /* margin-bottom: 340px; */
}
.ol_mb300 li {
  margin-bottom: 33px;
}
.capture_area {
  margin: 0px;

}
.content_box.mt10 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
